<template>
    <b-container fluid>
        <b-card no-body class="mb-2 p-1">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="primary" @click="applyFilters">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">Фильтры</b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </b-row>
            <b-collapse id="filters" class="mt-2 text-center">
                <b-row>
                    <b-col md="3">
                        <b-form-group description="Фильтр по цеху">
                            <storage-picker v-model="filters.storage" :nullable="true" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group description="Фильтр по наименованию">
                            <b-form-input v-model="filters.search_name" type="text" debounce="500" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-collapse>
        </b-card>
        <b-table
            hover
            responsive
            head-variant="dark"
            :items="items"
            :fields="fields"
            @row-dblclicked="toForm"
        >
            <template #cell(name)="row">
                <b-img
                    blank
                    :blank-color="row.item.color"
                    width="25"
                    height="18"
                    alt="placeholder"
                /> {{ row.item.name }}
            </template>
            <template #cell(actions)="row">
                <b-button
                    @click="toForm(row.item)"
                    size="sm"
                    variant="primary"
                >
                    <b-icon-pencil-square/>
                </b-button>
            </template>
        </b-table>

        <b-row>
            <b-col cols="6">
                <span>Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }} по {{ meta.current_page * meta.per_page - meta.per_page + items.length }} из {{ meta.total_rows }} записей</span>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import StoragePicker from '../../components/_common/StoragePicker';
    import {OPERATIONS_ENDPOINT} from '../../utils/endpoints';
    import lsfMixin from '../../utils/localStorageFilterMixin';

    const FILTER_PATTERN = {
        storage: null,
        search_name: null,
    };

    export default {
        name: 'Index',
        components: {
            StoragePicker
        },
        mixins: [lsfMixin],
        data() {
            return {
                items: [],
                fields: [
                    {
                        key: 'name',
                        label: 'Наименование',
                    },
                    {
                        key: 'has_shock_chilling',
                        label: 'Требуется шоковое охлаждение',
                        formatter(value) {
                            return value === true ? 'Да' : 'Нет';
                        }
                    },
                    {
                        key: 'storages',
                        label: 'Цех',
                        formatter(values) {
                            return values.length !== 0
                                ? values.map((value) => value.name).join(', ')
                                : null;
                        }
                    },
                    {
                        key: 'actions',
                        label: 'Действия',
                    },
                ],
                meta: {
                    current_page: 1,
                    per_page: 0,
                    total_rows: 0,
                },
                filters: {
                    storage: null,
                    search_name: null,
                }
            };
        },
        watch: {
            filters: {
                handler: function (value) {
                    this.watchFilters(value);
                },
                deep: true
            },
        },
        methods: {
            applyFilters() {
                this.load();
            },
            load() {
                let params = {
                    page: this.meta.current_page,
                    with: [
                        'storages'
                    ],
                    orders: {
                        name: 'asc'
                    },
                    filters: {

                    },
                };

                if (this.filters.storage) {
                    params.filters.storage_id = this.filters.storage.id;
                }

                if (this.filters.search_name) {
                    params.filters.search_name = this.filters.search_name;
                }

                this.$http.get(OPERATIONS_ENDPOINT, {params})
                    .then((response) => {
                        this.items = response.data.data.data;
                        this.meta.current_page = response.data.data.current_page;
                        this.meta.per_page = response.data.data.per_page;
                        this.meta.total_rows = response.data.data.total;
                    }).catch((error) => this.$root.responseError(error, 'Ошибка загрузки списка'));
            },
            toForm(item) {
                this.$router.push({
                    name: 'OperationUpdate',
                    params: {
                        id: item.id
                    }
                });
            }
        },
        mounted() {
            this.initFilters(this, FILTER_PATTERN);
        },
    }
</script>
